<template>
  <div class="AutomationDetails">
    <header class="AutomationDetails__header">
      <div
        class="AutomationDetails__header-container d-flex justify-space-between align-center"
      >
        <div class="d-flex align-center">
          <p class="AutomationDetails__header-title">
            <router-link
              to="/workflows"
              tag="a"
              class="AutomationDetails__header-title-link"
            >
              Workflows
            </router-link>
            <v-icon small class="AutomationDetails__header-title-icon">
              mdi-chevron-right
            </v-icon>
            <span
              class="AutomationDetails__header-title-name"
              v-if="workflow.name"
            >
              {{ workflow.name }}
            </span>
            <span class="AutomationDetails__header-title-no-name" v-else>
              Workflow name here
            </span>
          </p>
        </div>
        <div class="d-flex justify-space-between" v-if="!isHistory">
          <v-btn
            color="success"
            :disabled="
              !canWorkflowBeSaved || isWorkflowRunning || isWorkflowSaving
            "
            @click="handleShowProductionModal"
            :loading="running.runningInProductionMode && isWorkflowRunning"
            elevation="0"
            outlined
          >
            <v-icon class="mr-3" right>mdi-bug-check</v-icon>Production Run
          </v-btn>

          <div class="AutomationDetails__header-button-divider" />

          <v-btn
            color="primary"
            elevation="0"
            :disabled="!canWorkflowBeSaved || isWorkflowSaving"
            @click="handleSave"
            :loading="isWorkflowSaving"

          >
            Save Workflow
          </v-btn>
        </div>
      </div>
    </header>

    <header class="AutomationDetails__sub-header">
      <v-tabs
        height="35"
        active-class="AutomationDetails__tab--active"
        :hide-slider="true"
        class="AutomationDetails__tabs"
      >
        <v-tab
          :to="`/workflows/${automationId}/history`"
          :disabled="isNew"
          @click="goToHistory()"
        >
          History
        </v-tab>
        <v-tab :to="`/workflows/${automationId}/builder`"> Builder </v-tab>
      </v-tabs>
    </header>

    <div class="AutomationDetails__content">
      <router-view />

      <action-prompt
        v-model="showProductionWarnDialog"
        v-if="showProductionWarnDialog"
        :action-text="dialogActionText"
        :action="handleRunInProduction"
        :header="dialogTitle"
        :body="dialogBodyText"
        :width="500"
        action-type="primary"
      />

      <set-input-modal />
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import ActionPrompt from '@/components/modals/action-prompt/action-prompt.vue';
import { workflowStatus } from '@/util/workflow-statuses';
import cloneDeep from 'lodash/cloneDeep';
import SetInputModal from './modals/set-input-popup/set-input-popup.vue';

const { mapActions: sharedActions } = createNamespacedHelpers('shared');
const { mapActions: credsActions } = createNamespacedHelpers('credentials');
const {
  mapActions: workflowDetailsActions,
  mapGetters: workflowDetailsGetters,
  mapMutations: workflowDetailsMutations,
} = createNamespacedHelpers('workflows/details');
const { mapGetters: customerGetters } = createNamespacedHelpers('customer');
const { mapActions: devicesActions } = createNamespacedHelpers('devices');

export default {
  name: 'AutomationDetails',
  components: {
    ActionPrompt,
    SetInputModal,
  },
  data() {
    return {
      showProductionWarnDialog: false,
      dialogTitle: 'Run workflow in production mode',
      dialogBodyText: `
      You are going to run the workflow in production mode. In this mode
      REAL data will be used and the process CANNOT be stopped. Are you sure
      you want to run this workflow in production mode?`,
      dialogActionText: 'Run in production',
    };
  },
  methods: {
    ...sharedActions(['toggleSidebar', 'toggleHeader', 'getServiceTypes']),
    ...workflowDetailsActions([
      'fetchWorkflow',
      'clearWorkflow',
      'fetchOutputModels',
      'fetchAppointmentOptions',
      'saveWorkflow',
      'updateWorkflow',
      'runWorkflow',
      'getWorkflowStatus',
      'workflowSetup',
    ]),
    ...credsActions(['fetchAuthentications']),
    ...devicesActions(['fetchDevices']),
    ...workflowDetailsMutations({
      setWorkflowRunningObj: 'SET_WORKFLOW_RUNNING_OBJ',
    }),
    goToHistory() {
      this.handleSave();
    },
    handleSave() {
      if (this.isNew) {
        this.saveWorkflow();
      } else {
        this.updateWorkflow();
      }
    },
    handleRunInProduction() {
      this.showProductionWarnDialog = false;
      this.runWorkflow({ isProduction: true, isNew: this.isNew });
    },
    handleShowProductionModal() {
      this.showProductionWarnDialog = true;
    },
    resumeRunningWorkflow() {
      const running = cloneDeep(this.running);
      const workflowId = this.workflow.id;
      if (running?.workflow_status === workflowStatus.PENDING) {
        running.PAUSE_UPDATES = false;
        running.workflowId = workflowId;
        this.setWorkflowRunningObj(running);
        this.getWorkflowStatus({
          storage_id: running.storageId,
          firstCall: false,
          workflowId,
        });
      }
    },
    pauseRunningWorkflow() {
      const running = cloneDeep(this.running);
      if (running?.workflow_status === workflowStatus.PENDING) {
        running.PAUSE_UPDATES = true;
        running.workflowId = this.workflow.id;
        this.setWorkflowRunningObj(running);
      }
    },
  },
  computed: {
    ...workflowDetailsGetters({
      workflow: 'WORKFLOW',
      canWorkflowBeSaved: 'CAN_WORKFLOW_BE_SAVED',
      saveWorkflowLoading: 'SAVE_WORKFLOW_LOADING',
      updateWorkflowLoading: 'UPDATE_WORKFLOW_LOADING',
      runStatus: 'WORKFLOW_RUNNING_OBJ',
      workflowRunning: 'WORKFLOW_RUNNING',
      workflowStarted: 'WORKFLOW_STARTED',
      checkingWorkflowStatus: 'CHECK_AUTOMATION_WORKFLOW_STATUS_LOADING',
    }),
    ...customerGetters({ customer: 'CUSTOMER' }),
    automationId() {
      return this.$route.params.automationId;
    },
    isNew() {
      return this.automationId === 'new' || !this.automationId;
    },
    isWorkflowRunning() {
      return (
        this.workflowRunning ||
        this.workflowStarted ||
        this.checkingWorkflowStatus ||
        this.running.workflow_status === 'PENDING'
      );
    },
    isWorkflowSaving() {
      return this.saveWorkflowLoading || this.updateWorkflowLoading;
    },
    isHistory() {
      return this.$route.name === 'History';
    },
    running() {
      const workflowId = this.workflow.id;
      if (workflowId) {
        return this.runStatus(workflowId);
      }
      return {};
    },
  },
  mounted() {
    this.toggleSidebar(false);
    this.toggleHeader(false);
    this.fetchAuthentications();
    if (this.automationId && this.automationId !== 'new') {
      this.fetchWorkflow(this.automationId);
    } else {
      this.workflowSetup();
    }
    this.fetchOutputModels();
    this.fetchAppointmentOptions();
    this.getServiceTypes();
    this.fetchDevices();
    this.resumeRunningWorkflow();
  },
  beforeDestroy() {
    this.pauseRunningWorkflow();
    this.clearWorkflow();
    this.toggleHeader(true);
  },
};
</script>

<style scoped lang="scss">
@import './automation-details';
</style>
